:root { 
--gray_600:#707070; 
--black_900_1a:#0000001a; 
--red_600:#e93d4a; 
--gray_900:#242424; 
--gray_900_01:#040d2c; 
--white_A700_90:#ffffff90; 
--light_blue_500_87:#00adef87; 
--light_blue_500:#00adef; 
--gray_200:#eaeaea; 
--white_A700:#ffffff; 
--gray_300:#e3e3e3; 
--gray_100:#f6f6f6; 
}

 /* @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/PoppinsRegular.ttf"); font-family : "Poppins"; font-weight : 400;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/PoppinsSemiBold.ttf"); font-family : "Poppins"; font-weight : 600;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/PoppinsMedium.ttf"); font-family : "Poppins"; font-weight : 500;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/NunitoSansBold.ttf"); font-family : "Nunito Sans"; font-weight : 700;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/NunitoSansRegular.ttf"); font-family : "Nunito Sans"; font-weight : 400;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/NunitoSansSemiBold.ttf"); font-family : "Nunito Sans"; font-weight : 600;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/NunitoSansExtraBold.ttf"); font-family : "Nunito Sans"; font-weight : 800;  } */

 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/PoppinsRegular.ttf"); font-family : "Poppins"; font-weight : 400;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/PoppinsSemiBold.ttf"); font-family : "Poppins"; font-weight : 600;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/Futura Medium/Futura Medium.otf"); font-family : "Futura"; font-weight : 500;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/Futura Bold/Futura Bold.otf"); font-family : "Futura"; font-weight : 700;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/Futura Regular/Futura Regular.ttf"); font-family : "Futura"; font-weight : 400;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/Futura Heavy/Futura Heavy.otf"); font-family : "Futura"; font-weight : 600;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/Futura Extra Bold Condensed/Futura Extra Bold Condensed.otf"); font-family : "Futura"; font-weight : 800; }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/Futura Extra Bold Condensed/Futura Extra Bold Condensed.otf"); font-family : "Futura"; font-weight : 900;  }